
<div class="ub04-list-div">
  <mat-grid-list cols="{{ub04Count + 1}}" rowHeight="200px" gutterSize="15px">
    <ng-container *ngFor="let ub04 of ub04List">
      <mat-grid-tile>
        <mat-card class="mat-elevation-z8" [ngStyle]="getSelectedStyle(ub04)">
          <mat-card-header>
            <mat-card-title>UB04 - {{ub04.ub04VisitType}}</mat-card-title>
            <mat-card-subtitle>
              Id: {{ub04.id}} Created: {{ub04.createdDate | date: 'MM/dd/yyyy'}}
              <br>
              {{ub04.ub04ReviewTypeLabel}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              NPI Number: {{ub04.npiNumber}}
            </p>

            <h4 *ngIf="!ub04.active">
              Deactivated
            </h4>
          </mat-card-content>

          <mat-card-actions class="alt-theme">
            <button mat-raised-button color="accent" (click)="selectUb04(ub04.id)">Select</button>
            <div style="float: right" 
              matTooltip="Designated for reports and dashboards."
              *ngIf="ub04.caseLevelReportingUB04">
              <mat-icon>assignment</mat-icon>
            </div>

            <mat-menu #appMenu="matMenu">
              <button
                mat-menu-item
                color="accent"
                (click)="createItemized(ub04.id)">
                <mat-icon color="accent">add_circle</mat-icon>
                <span>Create Manual Itemized</span>
              </button>
              <button
                mat-menu-item
                color="accent"
                (click)="generateUB04Report(ub04.id)">
                <mat-icon color="accent">add_circle</mat-icon>
                <span>Create PDF for UB04</span>
              </button>
              <button
                *ngIf="userCanGenerateIBRequest"
                mat-menu-item
                color="accent"
                (click)="promptToGenerateIBRequest(ub04)">
                <mat-icon color="accent">blur_linear</mat-icon>
                <span>Generate IB Request</span>
              </button>              
              <button
                *ngIf="userCanDeleteUB04"
                mat-menu-item
                color="accent"
                (click)="toggleActiveStatus(ub04)">
                <mat-icon color="accent">{{ub04.active ? "delete" : "restore_from_trash"}}</mat-icon>
                <span>{{ub04.active ? "Deactivate" : "Reinstate"}}</span>
              </button>
            </mat-menu>
            <button class="menu-icon" mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </ng-container>
    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center create-ub04-card">
        <mat-card-header>
          <mat-card-title>Create UB04</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Create a new UB04 for <br>Case: {{miCaseId}}
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="createUB04()">Create UB04</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-paginator #ub04Paginator
    [pageSize]="defaultPageSize"
    [length]="totalRowCount"
    (page)="pageHandler()"
  ></mat-paginator>

</div>

<div *ngIf="ub04Available()" class="dashboard-div-wrapper">
  <mat-grid-list cols="4" rowHeight="200px" gutterSize="15px">
    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center" [ngStyle]="getStatusTrackStyle('UB04_EDIT1')">
        <mat-card-header>
          <mat-card-title>Enter General Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Patient Control No, Bill Type, Federal Tax Id, Medical Control No
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04Info1()">Edit General Info</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center" [ngStyle]="getStatusTrackStyle('UB04_EDIT_PROVIDER')">
        <mat-card-header>
          <mat-card-title>Enter Provider Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Hospital, Surgeon, Care Giver
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04Provider()">Edit Provider Info</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>


    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center" [ngStyle]="getStatusTrackStyle('UB04_EDIT_PAYER')">
        <mat-card-header>
          <mat-card-title>Enter Payer Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Insurance Information
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUB04Payers()">Edit Payer Info</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center" [ngStyle]="getStatusTrackStyle('UB04_EDIT_PATIENT')">
        <mat-card-header>
          <mat-card-title>Enter Patient Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Address, Name, BirthDate, ..
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04Patient()">Edit Patient Info</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8 title-center" [ngStyle]="getStatusTrackStyle('UB04_EDIT_OCCURRENCE_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Occurrence Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter related Occurrence Codes
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04OccurrenceCodes()">Edit Occurrence Codes</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_REV_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Revenue Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter revenue codes.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04RevenueCodes()">Edit Revenue Codes</button>
          <div *ngIf="activityLogAccess" 
            class="activity-log-viewer-button" 
            matTooltip="Activity Log"
            (click)="openUB04ChildActivityLog('UB04_REVENUE_CODE')">
            <mat-icon>view_kanban</mat-icon>
          </div>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_CONDITION_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Condition Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter Condition codes.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04ConditionCodes()">Edit Condition Codes</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_VALUE_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Value Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter value codes.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04ValueCodes()">Edit Value Codes</button>
          <div *ngIf="activityLogAccess" 
            class="activity-log-viewer-button" 
            matTooltip="Activity Log"
            (click)="openUB04ChildActivityLog('UB04_VALUE_CODE')">
            <mat-icon>view_kanban</mat-icon>
          </div>          
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_DIAGNOSIS_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Diagnosis Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter diagnosis codes.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04DiagnosisCodes()">Edit Diagnosis Codes</button>
          <div *ngIf="activityLogAccess" 
            class="activity-log-viewer-button" 
            matTooltip="Activity Log"
            (click)="openUB04ChildActivityLog('UB04_DIAGNOSIS_CODE')">
            <mat-icon>view_kanban</mat-icon>
          </div>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_PROCEDURE_CODE')">
        <mat-card-header>
          <mat-card-title>Enter Procedure Codes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Enter diagnosis codes.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUb04ProcedureCodes()">Edit Procedure Codes</button>
          <div *ngIf="activityLogAccess" 
            class="activity-log-viewer-button" 
            matTooltip="Activity Log"
            (click)="openUB04ChildActivityLog('UB04_PROCEDURE_CODE')">
            <mat-icon>view_kanban</mat-icon>
          </div>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card class="mat-elevation-z8" [ngStyle]="getStatusTrackStyle('UB04_EDIT_REMARKS')">
        <mat-card-header>
          <mat-card-title>Enter Other Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Remarks.
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="accent" (click)="editUB04Remarks()">Edit Other Information</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>

  </mat-grid-list>

</div>


import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  Ub04OccurrenceCodeDialogComponent
} from "../../ub04/ub04-occurrence-code-dialog/ub04-occurrence-code-dialog.component";
import { Ub04EditPatientDialogComponent } from "../../ub04/ub04-edit-patient-dialog/ub04-edit-patient-dialog.component";
import { BaseComponent } from "../../view/base.component";
import { Ub04ValueCodeDialogComponent } from "../../ub04/ub04-value-code-dialog/ub04-value-code-dialog.component";
import {
  Ub04ConditionCodeDialogComponent
} from "../../ub04/ub04-condition-code-dialog/ub04-condition-code-dialog.component";
import { Ub04RemarksDialogComponent } from "../../ub04/ub04-remarks-dialog/ub04-remarks-dialog.component";
import { DateUtil } from "../../util/date-util";
import { UB04CreateComponent } from "../../ub04/ub04-create/ub04-create.component";
import { StatusTrackService } from "../../status-track/status-track.service";
import { UB04ReportPacket } from "../../model/net/ub04-report-packet";
import { SingleChoiceDialogComponent } from "../../dialog/single-choice-dialog/single-choice-dialog.component";
import { ItemizedCreateComponent } from "../../itemized/itemized-create/itemized-create.component";
import {
  Ub04DiagnosisCodeDialogComponent
} from "../../ub04/ub04-diagnosis-code-dialog/ub04-diagnosis-code-dialog.component";
import { Ub04RevenueCodeDialogComponent } from "../../ub04/ub04-revenue-code-dialog/ub04-revenue-code-dialog.component";
import { Ub04PayerDialogComponent } from "../../ub04/ub04-payer-dialog/ub04-payer-dialog.component";
import {
  Ub04EditProcedureCodeDialogComponent
} from "../../ub04/ub04-edit-procedure-code-dialog/ub04-edit-procedure-code-dialog.component";
import { MICaseService } from "../mi-case.service";
import { UB04EditDialog1Component } from "../../ub04/ub04-edit-dialog1/ub04-edit-dialog1.component";
import {
  Ub04EditProviderDialogComponent
} from "../../ub04/ub04-edit-provider-dialog/ub04-edit-provider-dialog.component";
import { Ub04Service } from "../../ub04/ub04.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../util/logger";
import { UB04GenIBRequestDialogComponent } from '../../ub04/ub04-gen-ib-request-dialog/ub04-gen-ib-request-dialog.component';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivityLogDialogComponent } from '../../activity-log/activity-log-dialog/activity-log-dialog.component';
import { ActivityLogDialogConfig } from '../../model/activity-log-dialog-config';

@Component({
  selector: 'app-mi-case-ub04',
  templateUrl: './mi-case-ub04.component.html',
  styleUrls: ['./mi-case-ub04.component.scss']
})
export class MiCaseUb04Component extends BaseComponent implements OnInit, AfterViewInit {

  // protected snack: FennecSnackbarService;
  protected log: Logger = new Logger("MiCase");

  @Input()
  miCaseId: string = "-1";

  @Input()
  selectedUb04Id: string = "-1";

  selectedUb04Type: string = "";

  // A dto that tracks the status of the ub04 during data entry, for visual feedback;
  ub04StatusTrack: any;
  ub04List: any [] = [];
  ub04Count: number = 0;

  statusTrackList: any [] = [];
  openTask = false;

  // Set to TRUE to reposition the display to the highest UB04 id in the UB04 list. This is
  // a poor man's way of positioning the user on the most recently added UB04 after they
  // perform an add.
  repositionToHighestUB04Id: boolean = false;

  // Front End role indicators used to prevent/allow functionality based upon a user's role.
  userCanDeleteUB04: boolean = false;
  userCanGenerateIBRequest: boolean = false;
  activityLogAccess: boolean = false;

  @ViewChild("ub04Paginator")
  paginator?: MatPaginator;

  defaultPageSize:number = 4;
  totalRowCount:number = 0;

  constructor(
    protected snack: FennecSnackbarService,
    private statusTrackService: StatusTrackService,
    private ub04Service: Ub04Service,
    private miCaseService: MICaseService,
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public keycloakService: KeycloakService,
  ) {
    super();
    const roleList = this.keycloakService.getUserRoles();
    if (roleList.includes("ADMIN") || roleList.includes("UB04_DELETE")) {
      this.userCanDeleteUB04 = true;
    }
    if (roleList.includes("ADMIN") || roleList.includes("GENERATE_IB_REQUEST")) {
      this.userCanGenerateIBRequest = true;
    }
    this.activityLogAccess = roleList.includes("ADMIN") || roleList.includes("CASE_ACTIVITY_LOG");
  }
  
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    // Router path
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    this.getUb04List();
  }

  ub04Available(): boolean {
    return parseInt(this.selectedUb04Id) >= 0 && this.ub04Count > 0;
  }

  selectUb04(ub04Id: number): void {
    this.selectedUb04Id = ub04Id.toString();
    this.getUb04Info();
  }

  getSelectedStyle(ub04: any):any {
    if (ub04?.id.toString() === this.selectedUb04Id) {
      return {
        "border": "2px solid powderblue",
        "background-color": ub04.active ? "powderblue" : "rgba(150, 0, 0, 0.3)",
        "font-weight": "bold"
      }
    } else {
      return {
        "background-color": ub04.active ? "#E5E6EB" : "rgba(200, 0, 0, 0.3)",
        "font-weight": "normal"
      }
    }
  }

  getStatusTrackStyle(element: string) {
    const status = this.getStatusTrackStatusGivenElement(element);
    if (status === "IN_PROGRESS") {
      return {
        "background-color": "lightyellow"
      }
    } else if (status === "COMPLETE") {
      return {
        "background-color": "lightgreen"
      }
    } else {
      return {
        "background-color": "lightgray"
      }
    }
  }

  private getStatusTrackStatusGivenElement(element: string): string {
    let status = "NONE";
    let stRow = this.statusTrackList.find((st) => st.statusTrackElement === element);
    if (stRow !== null && stRow !== undefined) {
      status = stRow.statusTrackStatus;
    }
    return status;
  }

  getUb04List() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.ub04Service.getUb04ListForMICase(parseInt(this.miCaseId), first, pageSize).subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.totalRowCount = response.totalRowCount;
          this.ub04List = response.data;
          if (this.ub04List !== null && this.ub04List !== undefined) {
            this.ub04Count = this.ub04List.length;
            if (this.ub04Count > 0) {
              if (parseInt(this.selectedUb04Id) >= 0) {
                this.selectUb04(parseInt(this.selectedUb04Id));
              } else if (this.repositionToHighestUB04Id) {
                this.repositionToHighestUB04();
              } else {
                this.selectUb04(this.ub04List[0].id);
              }
              this.getUb04StatusTrackList();
            }
          } else {
            this.ub04Count = 0;
          }
        }
      }
    )
  }

  getUb04StatusTrackList() {
    if (parseInt(this.selectedUb04Id) < 0) {
      this.statusTrackList = [];
      return;
    }
    this.statusTrackService.getStatusTrackListForDomainRelatedId(StatusTrackService.DOMAIN_UB04_DATA_ENTRY,
      parseInt(this.selectedUb04Id))
    .subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.statusTrackList = response.data;
        }
      }
    )
  }

  createItemized(ub04Id: number) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
      ub04Id: parseInt(this.selectedUb04Id, 10),
      origin: 'MANUAL'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.router.navigateByUrl(`/mi-case-itemized/${this.miCaseId}/${result.id}`);
      }
    });
  }

  getUb04Info() {
    this.ub04Service.getUb04Info(parseInt(this.selectedUb04Id.toString())).subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.ub04StatusTrack = response.data;
          this.getUb04StatusTrackList();
        }
      }
    );
  }

  editUb04Info1() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(UB04EditDialog1Component, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04List()
      this.getUb04Info();
    });
  }

  editUb04Provider() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "90%";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04EditProviderDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  editUb04Patient() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04EditPatientDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  editUb04RevenueCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04RevenueCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  editUb04DiagnosisCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "100%";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04DiagnosisCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  editUb04ConditionCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04ConditionCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getUb04Info();
      }
    });
  }

  editUb04OccurrenceCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04OccurrenceCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getUb04Info();
      }
    });
  }

  editUb04ValueCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04ValueCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getUb04Info();
      }
    });
  }

  editUb04ProcedureCodes() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04EditProcedureCodeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getUb04Info();
      }
    });
  }

  createUB04() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
    };
    const dialogRef = this.matDialog.open(UB04CreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.repositionToHighestUB04Id = true;
        this.selectedUb04Id = "-1";
        this.getUb04List();
      }
    });
  }

  // Repositions the display to the highest UB04 in the UB04 list for some UI sugar.
  private repositionToHighestUB04() {
    let highestUB04Id = -1;
    this.ub04List.forEach((ub04) => {
      if (ub04.id > highestUB04Id) {
        highestUB04Id = ub04.id;
      }
    });
    if (highestUB04Id >= 0) {
      this.selectUb04(highestUB04Id);
    }
    this.repositionToHighestUB04Id = false;
  }

  editUB04Remarks() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id,

    };
    const dialogRef = this.matDialog.open(Ub04RemarksDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  editUB04Payers() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      ub04Id : this.selectedUb04Id
    };
    const dialogRef = this.matDialog.open(Ub04PayerDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUb04Info();
    });
  }

  comingSoon() {
    alert("Coming Soon!");
  }

  generateUB04Report(ub04Id: number) {
    const packet = new UB04ReportPacket();
    packet.ub04Id = ub04Id;
    packet.miCaseId = parseInt(this.miCaseId);
    this.ub04Service.generateUb04Report(packet).subscribe(result => {
      if(result.hasErrors) {
        this.showErrorSnack("Error!");
      }else {
        this.showSuccessSnack("Generating UB04 Report");
      }
    });
  }

  submitMICase() {
    this.performXFRequest({
      requestDescription: "Submit MI Case to start tasking in XFWFL",
      requestFn: this.miCaseService.submitMICase,
      fnParams: [parseInt(this.miCaseId)],
      onSuccess: data => {
        super.showSuccessSnack(data);
        //this.openTaskCheck();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  openTaskCheck() {
    this.performXFRequest({
      requestDescription: "Check for a workflow task related to selected MI Case",
      requestFn: this.miCaseService.openTaskCheck,
      fnParams: [parseInt(this.miCaseId)],
      onSuccess: data => {
        this.openTask = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  formatDate(ub04: any) {
    return DateUtil.getDisplayDate(ub04.createdDate);
  }

  toggleActiveStatus(ub04:any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `${ub04?.active ? "Deactivate" : "Reinstate"} UB04 ID: ${ub04?.id}?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.ub04Service.toggleUB04ActiveStatus(ub04?.id).subscribe(result => {
          if(result.hasErrors) {
            super.showErrorSnack(result.consolidatedErrorMessage);
          }else {
            this.getUb04List();
          }
        });
      }
    });
  }

  promptToGenerateIBRequest(ub04: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.data = {
      ub04Id: ub04.id
    };
    const dialogRef = this.matDialog.open(UB04GenIBRequestDialogComponent, matDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {

      }
    });
  }

  pageHandler() {
    this.getUb04List();
  }

  openUB04ChildActivityLog(activityLogEntity: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";

    const aldConfig = new ActivityLogDialogConfig();
    aldConfig.ub04ChildEntity = true;
    aldConfig.activityLogEntity = activityLogEntity;
    aldConfig.relatedId = parseInt(this.miCaseId);
    aldConfig.ub04Id = parseInt(this.selectedUb04Id);
    matDialogConfig.data = aldConfig;
    const dialogRef = this.matDialog.open(ActivityLogDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

}
